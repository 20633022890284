/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
} from '@chakra-ui/react';
import _ from 'lodash';
import hash from 'object-hash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import { TASK_STATUS } from '../../../../config/TaskStatus';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import { uploadImageFile } from '../../../../util/image.functions';
import { OtherData } from '../../../../config/WordUtil';

export default function CreateTask() {
  const { register, handleSubmit } = useForm();
  const me = useSelector((state) => state.me);
  const department = useSelector((state) => state.department);
  const commonWorks = useSelector((state) => state.commonWork);
  const workstation = useSelector((state) => state.workstation);
  const taskState = useSelector((state) => state.task);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedCommonWork, setSelectedCommonWork] = useState('');
  const [selectedWorkstation, setSelectedWorkstation] = useState('');
  const [isModalReadyOpen, setIsModalReadyOpen] = useState(false);
  const [image, setImage] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const commonWorkList = () =>
    _.map(
      _.sortBy(
        _.filter(
          _.union(commonWorks, [
            {
              work_id: OtherData.id,
              work_name: OtherData.thai,
              department_id: selectedDepartment,
            },
          ]),
          (work) => work.department_id === selectedDepartment,
        ),
        ['work_name', 'asc'],
      ),
      (avaliableWork, index) => (
        <option key={index} value={avaliableWork.work_id}>
          {avaliableWork.work_name}
        </option>
      ),
    );

  const workstationList = () =>
    _.map(workstation?.arr, (eachStation, index) => (
      <option key={index} value={eachStation.workstation_id}>
        {eachStation.workstation_name}
      </option>
    ));

  const generateWorkNameFromId = (workId) => {
    const workData = _.find(commonWorks, (work) => work.work_id === workId);
    if (workData) return workData.work_name;
    return '';
  };

  const onSubmit = async (data, e) => {
    console.log('Data that summit ', data);
    setIsModalReadyOpen(true);
    const tempTaskPayload = data;
    // ใส่ค่าที่มันยังขาดอยู่ลงไป
    tempTaskPayload.task_issue_date = new Date();
    tempTaskPayload.task_issuer = me.employee_id;
    tempTaskPayload.task_status = TASK_STATUS.INITIAL;

    let imageDataString = [];

    for (let i = 0; i < _.size(image); i++) {
      if (image && image[i]) {
        console.log(` Image Available for image ${i + 1}`);
        const filename = hash({ image: image[i], date: new Date() });

        // อัพโหลลดรูปภาพ โดยเอารูปภาพที่ผู้ใช้ใส่มา Push ลงไปใน Array ImageDataString
        await uploadImageFile(filename, image[i].data_url).then(async (url) => {
          console.log(`URL of Image ${i + 1} : ${url}`);

          // เพื่อกันความผิดพลาด กลัวว่า Empty Array จะ Push แล้วมีปัญหา
          // ดังนั้น กรณีเป็นรูปแรก เราจะเปลี่ยนค่า Array เป็นค่า Array ของ String ที่มีค่าๆ นึง
          if (_.isEmpty(imageDataString)) {
            imageDataString = [url];
          } else {
            imageDataString.push(url);
          }

          // เมื่อถึงรูปตัวสุดท้าย มันจะเท่ากับขนาดของ image array - 1 ก็จะให้มันเก็บค่า
          // ของ Array ของ urlString ไว้ใน Attribute ของ iamgeDataString
          // จากนั้นก็ติดไปที่ payload ของ Action ไปด้วย
          // แล้วค่อยไปแยกอีกทีว่าอันไหน ไปที่ไหน ลงตารางไหนใน Database
          if (i === _.size(image) - 1) {
            tempTaskPayload.imageData = imageDataString;
            console.log('Upload Full of All images');
            console.log(tempTaskPayload);

            e.preventDefault();
            dispatch(actions.createNewTask(tempTaskPayload)).then((res) => {
              setImage([]);

              alert('เพิ่มงานใหม่สำเร็จ');
              setIsModalReadyOpen(false);
              dispatch(actions.taskAllMyAssign(me.employee_id));
              history.push('/wms/dashboard/myassign');
            });
          }
        });
      } else {
        // กรณีผู็ใช้ไม่ได้ใส่รูปมา ก็ไม่ต้องทำอะไร จัดการไปปกติ
        console.log('No Image');
        const submittedTaskPayload = tempTaskPayload;
        console.log('Task Payload', submittedTaskPayload);

        e.preventDefault();
        dispatch(actions.createNewTask(submittedTaskPayload)).then((res) => {
          console.log('Notify it');
          setImage([]);
          setIsModalReadyOpen(false);

          alert('เพิ่มงานใหม่สำเร็จ');
          dispatch(actions.taskAllMyAssign(me.employee_id));
          history.push('/wms/dashboard/myassign');
        });

        break;
      }
    }

    if (_.size(image) === 0) {
      console.log('No Image');
      const submittedTaskPayload = tempTaskPayload;
      console.log('Task Payload', submittedTaskPayload);

      e.preventDefault();
      dispatch(actions.createNewTask(submittedTaskPayload)).then((res) => {
        const currentTaskState = taskState;
        console.log('Curerent task state ', currentTaskState);

        console.log('Notify It');
        setImage([]);
        // Notify Data

        setIsModalReadyOpen(false);

        window.alert('เพิ่มงานใหม่สำเร็จ');

        dispatch(actions.taskAllMyAssign(me.employee_id));
        history.push('/wms/dashboard/myassign');
      });
    }
  };

  useEffect(() => {
    dispatch(actions.departmentAll()).then(() => {
      dispatch(actions.workstationAll());
      dispatch(actions.commonWorkAll()).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, []);

  if (isLoading === true) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
          แจ้งงานใหม่
        </h2>

        <div className="p-4">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <Modal isOpen={isModalReadyOpen}>
                <ModalOverlay />

                <ModalContent>
                  <ModalBody>
                    <Spinners />
                    <h4 className="text-lg text-center">
                      กำลังเพิ่มข้อมูลในระบบ
                    </h4>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    แผนกที่ต้องการมอบหมายงาน
                  </label>
                  <div>
                    <Select
                      placeholder="เลือกแผนก"
                      bg="white"
                      name="task_department"
                      onChange={(event) => {
                        setSelectedDepartment(event.target.value);
                      }}
                      ref={register}
                    >
                      {_.map(department, (eachDepartment, index) => (
                        <option
                          value={eachDepartment.department_id}
                          key={index}
                        >
                          {eachDepartment.department_name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    งานที่ต้องการมอบหมาย
                  </label>
                  <div>
                    <Select
                      placeholder="เลือกงานที่ต้องการมอบหมาย"
                      bg="white"
                      name="task_commonwork_id"
                      ref={register}
                      onChange={(event) => {
                        setSelectedCommonWork(event.target.value);
                      }}
                    >
                      {commonWorkList()}
                    </Select>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    หัวข้องาน
                  </label>
                  <textarea
                    name="task_title"
                    type="textfield"
                    ref={register}
                    className="px-3 py-3 placeholder-gray-400  text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    style={{ height: '200px' }}
                    placeholder="หัวข้องาน"
                    defaultValue={
                      selectedCommonWork === OtherData.thai
                        ? ''
                        : generateWorkNameFromId(selectedCommonWork)
                    }
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    โรงแรม
                    {/* TODO: Change to General */}
                  </label>
                  <div>
                    <Select
                      placeholder="เลือกสถานที่โรงแรมที่ต้องการ"
                      bg="white"
                      name="task_workstation"
                      ref={register}
                      onChange={(event) => {
                        setSelectedWorkstation(event.target.value);
                      }}
                    >
                      {workstationList()}
                    </Select>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    สถานที่
                  </label>
                  <input
                    name="task_place"
                    type="text"
                    ref={register}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="สถานที่ของงาน"
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    รูปภาพประกอบ
                  </label>
                  <label
                    className="block uppercase text-gray-400 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    สามารถเพิ่มได้สูงสุด 5 ภาพต่องาน
                  </label>
                  <ImageUpload
                    images={image}
                    setImages={setImage}
                    preview_size="100px"
                    maxNumber={5}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <div className="text-center mt-6">
                    <Button isFullWidth colorScheme="blue" type="submit">
                      มอบหมายงาน
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Spinners />;
}
