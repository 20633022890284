// สร้างพื้นที่สำหรับเก็บ Element ของตาราง
import React, { useState, useEffect } from 'react';
import _, { fromPairs } from 'lodash';
import moment from 'moment';
import { Table, Tbody, Th, Thead, Tr, Select, Button } from '@chakra-ui/react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import TableListComponent from '../Components/TableListComponent';
import { ALL_SELECT } from '../../../config/WordUtil';
import { TASK_STATUS } from '../../../config/TaskStatus';
import Logo from '../../../assets/img/seaseeker_logo_full.png';

export default function DepartmentTableWithFilter({ taskArray }) {
  // Data Status Filtering
  const [successAllow, setSuccessAllow] = useState(true);
  const [inprogressAllow, setInprogressAllow] = useState(true);
  const [unacceptAllow, setUnacceptAllow] = useState(true);

  // Filtered Task To Render
  const [dateFilteredTaskArray, setDateFilteredTaskArray] = useState(taskArray);
  const [filteredTaskArray, setFilteredTaskArray] = useState(
    dateFilteredTaskArray,
  );

  // Date Range Filtering
  const [selectedRange, setSelectedRange] = useState([
    moment().subtract(1, 'months').toDate(),
    moment().toDate(),
  ]);

  // Filter Date Range
  useEffect(() => {
    const afterStartRange = _.filter(taskArray, (task) =>
      moment(task.task_issue_date).isSameOrAfter(
        moment(selectedRange[0]),
        'days',
      ),
    );

    console.log('After Start Range ', afterStartRange);

    const beforeEndRange = _.filter(taskArray, (task) =>
      moment(task.task_issue_date).isSameOrBefore(
        moment(selectedRange[1]),
        'days',
      ),
    );
    console.log('Befire End Range ', beforeEndRange);

    const dataOnDuration = _.intersection(afterStartRange, beforeEndRange);
    setDateFilteredTaskArray(dataOnDuration);

    setSuccessAllow(true);
    setInprogressAllow(true);
    setUnacceptAllow(true);

    return () => {};
  }, [selectedRange]);

  // Filter from Task Status
  useEffect(() => {
    const afterSuccessFilter = successAllow
      ? dateFilteredTaskArray
      : _.filter(
          dateFilteredTaskArray,
          (task) => task.task_status !== TASK_STATUS.SUCCESS,
        );
    const afterInprogressFilter = inprogressAllow
      ? afterSuccessFilter
      : _.intersection(
          _.filter(
            afterSuccessFilter,
            (task) => task.task_status !== TASK_STATUS.IN_PROGRESS,
          ),
          _.filter(
            afterSuccessFilter,
            (task) => task.task_status !== TASK_STATUS.ACCEPT,
          ),
        );
    const afterUnAcceptFilter = unacceptAllow
      ? afterInprogressFilter
      : _.filter(
          afterInprogressFilter,
          (task) => task.task_status !== TASK_STATUS.INITIAL,
        );
    setFilteredTaskArray(afterUnAcceptFilter);
    return () => {};
  }, [successAllow, inprogressAllow, unacceptAllow, dateFilteredTaskArray]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg print-area  ">
        <div className="px-\ ">
          <br />
          <div className="flex flex-wrap print-disable">
            <div className=" px-4 lg:w-3/12">
              <p>วันและเวลา</p>
              <DateRangePicker
                value={selectedRange}
                onChange={setSelectedRange}
                clearIcon={null}
              />
            </div>

            <div className=" px-4 lg:w-4/12">
              <p>สถานะการทำงาน</p>
              <div className=" flex gap-1">
                <Button
                  size="sm"
                  colorScheme={successAllow ? 'green' : 'gray'}
                  onClick={() => setSuccessAllow(!successAllow)}
                >
                  <i className="fas fa-check-circle mr-2" />
                  สำเร็จ
                </Button>
                <Button
                  size="sm"
                  colorScheme={inprogressAllow ? 'green' : 'gray'}
                  onClick={() => setInprogressAllow(!inprogressAllow)}
                >
                  <i className="fas fa-check-circle mr-2" />
                  ดำเนินการ
                </Button>
                <Button
                  size="sm"
                  colorScheme={unacceptAllow ? 'green' : 'gray'}
                  onClick={() => setUnacceptAllow(!unacceptAllow)}
                >
                  <i className="fas fa-check-circle mr-2" />
                  ยังไม่มีผู้รับ
                </Button>
              </div>
            </div>

            <div className=" px-4 lg:w-1/12">
              <p>พิมพ์</p>
              <div className=" rounded-md  flex gap-1">
                <Button size="sm" onClick={() => window.print()}>
                  PDF
                </Button>
              </div>
            </div>
          </div>

          <div className="print-only text-left">
            <div className="flex justify-center">
              <div className="flex w-2/3 gap-2 font-medium">
                <img src={Logo} className="h-12" />
                <div>
                  <h6 className="text-sm">Sea Seeker Krabi Resort</h6>
                  <h4>รายการการแจ้งงาน / Work Order</h4>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-center">
              <hr className="w-4/5" />
            </div>
          </div>

          <div className=" my-4 mx-4 overflow-x-auto print-area ">
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>หัวข้องาน</Th>
                  <Th>วันที่แจ้ง</Th>
                  <Th>วันที่สำเร็จ</Th>
                  <Th>แผนกที่ทำงาน</Th>
                  <Th>สถานที่</Th>
                  <Th>สถานะ</Th>
                  <Th>ผู้รับงาน</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredTaskArray &&
                _.isArray(filteredTaskArray) &&
                !_.isEmpty(filteredTaskArray) ? (
                  _.map(filteredTaskArray, (task, index) => (
                    <TableListComponent
                      index={index}
                      taskData={task}
                      key={index}
                    />
                  ))
                ) : (
                  <>
                    <p className="text-center my-4"> ยังไม่มีรายการสั่งงาน</p>
                  </>
                )}
              </Tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
