import axios from 'axios';
import {
  TASK_ALL,
  TASK_GET,
  TASK_POST,
  TASK_PUT,
  TASK_DELETE,
  TASK_UNSUCCESS,
  TASK_UNACCEPT,
  TASK_INPROGRESS,
  TASK_SUCCESS,
  TASK_MY_ASSIGN,
  TASK_ASSIGN_ME,
  TASK_NOTIFY,
} from '../types';
import { TASK_STATUS as TaskStatus } from '../../../config/TaskStatus';
// eslint-disable-next-line import/no-cycle
import * as actions from '../index';

// Get all task
export const taskAll =
  (page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/task?page=${page}&size=${size}`)
      .then((res) => {
        console.log('Request to server to get all task');
        dispatch({ type: TASK_ALL, payload: res.data });
      });
  };

// Get One Task
export const taskGet = (taskId) => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/task/${taskId}`)
    .then((res) => {
      console.log('Request to Get selected Position from database');
      if (res.data) {
        dispatch({ type: TASK_GET, payload: res.data });
      } else {
        dispatch({ type: TASK_GET, payload: null });
      }
    });
};

// Find all task that you assign
export const taskAllMyAssign =
  (userId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/assigning/${userId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log('Request Server to select all my assignment in database');
        dispatch({ type: TASK_MY_ASSIGN, payload: res.data });
      });
  };

// Find all task that assign to you
export const taskAllAssignedToMe =
  (userId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/assigned/${userId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log(
          'Request Server to select all  assigned work to me in database',
        );
        dispatch({ type: TASK_ASSIGN_ME, payload: res.data });
      });
  };

// Get all unsuccessful  task
export const taskAllUnSuccessful =
  (page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/all/unsuccess?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log('Request to server to get all unsuccess task');
        dispatch({ type: TASK_UNSUCCESS, payload: res.data });
      });
  };

// Get all unaccept  task
export const taskAllUnAccept =
  (page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/all/unaccept?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log('Request to server to get all unaccept task');
        dispatch({ type: TASK_UNACCEPT, payload: res.data });
      });
  };

// Get all inprogress  task
export const taskAllInProgress =
  (page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/all/inprogress?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log('Request to server to get all inprogress task');
        dispatch({ type: TASK_INPROGRESS, payload: res.data });
      });
  };

// Get all success  task
export const taskAllSuccess =
  (page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/all/success?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log('Request to server to get all success task');
        dispatch({ type: TASK_SUCCESS, payload: res.data });
      });
  };

// Find all task that assign to each department
export const taskAllFromDepartment =
  (departmentId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/department/${departmentId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log(
          'Request Server to select all assign tp my department in database',
        );
        dispatch({ type: TASK_ALL, payload: res.data });
      });
  };

// Find all unsuccess task that assign to each department
export const taskDepartmentUnsuccessful =
  (departmentId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/department/unsuccess/${departmentId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log(
          'Request Server to select all unsuccess work those assign tp my department in database',
        );
        dispatch({ type: TASK_UNSUCCESS, payload: res.data });
      });
  };

// Find all unaccept task that assign to each department
export const taskDepartmentUnAccept =
  (departmentId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/department/unaccept/${departmentId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log(
          'Request Server to select all unaccept work those assign tp my department in database',
        );
        dispatch({ type: TASK_UNACCEPT, payload: res.data });
      });
  };

// Find all inprogress task that assign to each department
export const taskDepartmentInProgress =
  (departmentId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/department/inprogress/${departmentId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log(
          'Request Server to select all inprogress work those assign tp my department in database',
        );
        dispatch({ type: TASK_INPROGRESS, payload: res.data });
      });
  };

// Find all success task that assign to each department
export const taskDepartmentSuccess =
  (departmentId, page = 1, size = 10) =>
  async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/task/department/success/${departmentId}?page=${page}&size=${size}`,
      )
      .then((res) => {
        console.log(
          'Request Server to select all success work those assign tp my department in database',
        );
        dispatch({ type: TASK_SUCCESS, payload: res.data });
      });
  };

// Create Notify all around department
// prettier-ignore
export const taskNotifyDepartment = (departmentId, title, data, firstImage = undefined, url = '') => async (dispatch) => {
  console.log(
    `In Function Notify to all everyone in department ${departmentId}`,
  );
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}/notify/department/${departmentId}`,
      {
        title,
        data,
        image: firstImage,
        url,
      },
    )
    .then(() => {
      console.log('Request to Notify task Task');
      dispatch({ type: TASK_NOTIFY, payload: null });
    })
    .catch((error) => {
      console.log('cannot notify but create success', error);
      dispatch({ type: TASK_NOTIFY, payload: null });
    });
};

// Create Notify to one person directly
// prettier-ignore
export const taskNotifyDirecly = (userId, taskTitle, taskMetaData, url = '') => async (dispatch) => {
  console.log('Notify ', taskMetaData);
  await axios
    .post(`${process.env.REACT_APP_API_URL}/notify/direct/${userId}`, {
      title: taskTitle,
      data: taskMetaData,
      url,
    })
    .then(() => {
      console.log('Request to Notify task ');
      dispatch({ type: TASK_NOTIFY, payload: null });
    })
    .catch((error) => {
      console.log('cannot notify but create success', error);
      dispatch({ type: TASK_NOTIFY, payload: null });
    });
};

// Recieve Assignment/ Recieve Work
export const taskAccept = (taskId, userId) => async (dispatch) => {
  const dataToUpdate = {
    task_id: taskId,
    task_acceptance: userId,
    task_status: TaskStatus.ACCEPT,
    task_accept_date: new Date(),
  };
  await axios
    .put(`${process.env.REACT_APP_API_URL}/task/${taskId}`, dataToUpdate)
    .then((res) => {
      console.log('Request to server to accept work');
      dispatch({ type: TASK_PUT, payload: res.data });
    });
};

export const createNewTask = (payload) => async (dispatch) => {
  // Create New Task ส่งไปทั้งหมดเลย เรื่อง Image ไปว่ากันด้านหลัง
  await axios
    .post(`${process.env.REACT_APP_API_URL}/task`, payload)
    .then((res) => {
      console.log('Request Create New Task');
      dispatch(
        actions.taskNotifyDepartment(
          payload.task_department,
          `มีงานใหม่เข้ามาที่ ${payload.task_place}`,
          payload.task_title,
          undefined,
          `https://seaseeker.eappsoft.net/wms/dashboard/view/${res?.data?.task?.task_id}`,
        ),
      );
      dispatch(
        actions.lineTaskNotifyDepartment(
          payload.task_department,
          `มีงานเข้ามาใหม่ที่ ${payload.task_place} 😊 ${payload.task_title} ดูงานนี้เลยที่ https://seaseeker.eappsoft.net/wms/dashboard/view/${res?.data?.task?.task_id}   `,
        ),
      );
      dispatch({ type: TASK_POST, payload: res.data });
    });
};

export const taskEdit = (taskId, payload) => async (dispatch) => {
  await axios
    .put(`${process.env.REACT_APP_API_URL}/task/${taskId}`, payload)
    .then((res) => {
      console.log('Request to Edit Exisiting Task');
      dispatch({ type: TASK_PUT, payload: res.data });
    });
};

export const taskDelete = (taskId) => async (dispatch) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/task/${taskId}`)
    .then(() => {
      console.log('Request to Delete Exisiting Task');
      dispatch({ type: TASK_DELETE, payload: null });
    });
};
