import axios from 'axios';
import { COMMENT_CREATE, TASK_PUT } from '../types';

export const createNewComment = (payload) => async (dispatch) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/comment`, payload)
    .then((res) => {
      console.log('Request Create New Comment');
      dispatch({ type: COMMENT_CREATE, payload: res.data });
    });
};

export const deleteComment = (commentId) => async (dispatch) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/comment/${commentId}`)
    .then((res) => {
      console.log('Request Delete Comment');
      dispatch({ type: TASK_PUT, payload: res.data });
    });
};

export default { createNewComment, deleteComment };
