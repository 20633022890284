/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'object-hash';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';

import ImageUpload from 'components/ImageUpload/ImageUpload';
import { uploadImageFile } from '../../../util/image.functions';
import * as actions from '../../../redux/actions';
import LogTypes from '../../../config/LogTypes';

export default function EditEmployee() {
  const [image, setImage] = useState([]);
  const dispatch = useDispatch();

  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: information,
  });

  useEffect(() => {
    dispatch(actions.informationGet());

    return () => {};
  }, []);

  const onSubmit = async (data, e) => {
    const confirm = window.confirm('ยืนยันการแก้ไขข้อมูลระบบ');
    console.log('Payload ', data);
    if (confirm) {
      dispatch(
        actions.logCreate(
          me.employee_id,
          LogTypes.SYSTEM_EDIT,
          `แก้ไขข้อมูลระบบ `,
        ),
      );
      if (image === []) {
        dispatch(
          actions.informationPut(information?.information_id, data),
        ).then(async () => {
          alert('แก้ไขสำเร็จ');
          dispatch(actions.informationGet());
        });
      } else if (image && image[0]) {
        const filename = hash({ image, date: new Date() });
        uploadImageFile(filename, image[0]?.data_url).then(async (url) => {
          console.log(`URL : ${url}`);

          data.system_logo = url;
          e.preventDefault();
          // console.log(data);
          dispatch(
            actions.informationPut(information?.information_id, data),
          ).then(async () => {
            alert('แก้ไขสำเร็จ');
            dispatch(actions.informationGet());
          });
          setImage([]);
        });
      } else {
        dispatch(
          actions.informationPut(information?.information_id, data),
        ).then(async () => {
          alert('แก้ไขสำเร็จ');
          dispatch(actions.informationGet());
        });
      }
    }
  };

  return (
    <>
      <div>
        <div>
          <div>
            <div>
              <div>
                <h2 className="text-gray-800 text-xl font-semibold py-1">
                  แก้ไขข้อมูลระบบ
                </h2>
                <div className="flex justify-between py-6">
                  <Link to="/hrms/dashboard">
                    <Button variant="outline">กลับ</Button>
                  </Link>
                </div>
              </div>
              <div className="flex content-center items-center justify-center h-full mb-12">
                <div className="w-full lg:w-6/12 px-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          โลโก้ระบบ
                        </label>
                        <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                          <ImageUpload
                            images={image}
                            setImages={setImage}
                            preview_size="100px"
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อระบบ
                          </label>
                          <input
                            name="system_name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ชื่อระบบ"
                            defaultValue={information?.system_name}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อเต็มของบริษัทเจ้าของระบบ
                          </label>
                          <input
                            name="system_owner_name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ชื่อเต็มของบริษัทเจ้าของระบบ"
                            defaultValue={information?.system_owner_name}
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            URL ของระบบ
                          </label>
                          <input
                            name="system_url"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="URL ของระบบ"
                            defaultValue={information?.system_url}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ผู้สนับสนุนระบบ{' '}
                          </label>
                          <input
                            name="system_sponsor"
                            type="text"
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="URL ของระบบ"
                            defaultValue={information?.system_sponsor}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            URL ของผู้สนับสนุนระบบ
                          </label>
                          <input
                            name="system_sponsor_url"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="URL ของผู้สนับสนุนระบบ"
                            defaultValue={information?.system_sponsor_url}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="text-center px-4 gap-0 py-3">
                      <Button
                        isFullWidth
                        colorScheme="yellow"
                        variant="solid"
                        type="submit"
                      >
                        บันทึก
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
